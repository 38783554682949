"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.addSpectator = exports.fromSessionWaiting = exports.sessionWaiting = void 0;
var models_1 = require("./models");
var index_1 = require("./index");
var oneOf = function () {
    var args = [];
    for (var _i = 0; _i < arguments.length; _i++) {
        args[_i] = arguments[_i];
    }
    return args[Math.floor(Math.random() * args.length)];
};
var randomTokenColor = function () { return oneOf(models_1.Token.Blue, models_1.Token.Red); };
var sessionWaiting = function (roomId, socketId) { return ({
    kind: 'session-waiting',
    roomId: roomId,
    player1: { id: socketId, token: randomTokenColor() },
    board: index_1.emptyBoard
}); };
exports.sessionWaiting = sessionWaiting;
var fromSessionWaiting = function (session, socketId) {
    var player2 = { id: socketId, token: (0, models_1.otherToken)(session.player1.token) };
    return __assign(__assign({}, session), { kind: 'session-full', player2: player2, currentPlayer: oneOf(session.player1, player2), spectators: [], winners: [] });
};
exports.fromSessionWaiting = fromSessionWaiting;
var addSpectator = function (session, spectator) { return (__assign(__assign({}, session), { spectators: session.spectators.concat(spectator) })); };
exports.addSpectator = addSpectator;
