import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import process from 'process';
import { Buffer } from 'buffer';
import { SocketProvider } from './context/SocketProvider';
import { store } from './store';
import { Provider } from 'react-redux';

window.process = process;
window.Buffer = Buffer;

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
    <SocketProvider>
      <Provider store={store}>
        <App />
      </Provider>
    </SocketProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
