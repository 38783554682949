"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __exportStar = (this && this.__exportStar) || function(m, exports) {
    for (var p in m) if (p !== "default" && !Object.prototype.hasOwnProperty.call(exports, p)) __createBinding(exports, m, p);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.emptyBoard = exports.todo = void 0;
var models_1 = require("./models");
__exportStar(require("./models"), exports);
__exportStar(require("./utils"), exports);
__exportStar(require("./events"), exports);
__exportStar(require("./session"), exports);
function todo() {
    throw new Error("Not implemented");
}
exports.todo = todo;
exports.emptyBoard = [
    [{ id: '0', token: models_1.Token.Empty }, { id: '1', token: models_1.Token.Empty }, { id: '2', token: models_1.Token.Empty }, { id: '3', token: models_1.Token.Empty }],
    [{ id: '4', token: models_1.Token.Empty }, { id: '5', token: models_1.Token.Empty }, { id: '6', token: models_1.Token.Empty }, { id: '7', token: models_1.Token.Empty }],
    [{ id: '8', token: models_1.Token.Empty }, { id: '9', token: models_1.Token.Empty }, { id: '10', token: models_1.Token.Empty }, { id: '11', token: models_1.Token.Empty }],
    [{ id: '12', token: models_1.Token.Empty }, { id: '13', token: models_1.Token.Empty }, { id: '14', token: models_1.Token.Empty }, { id: '15', token: models_1.Token.Empty }],
];
