import { FC, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { MoveToken, Player, SessionFull, Space, Token, TokenLocation, isAdjacent, movedTokenTo, noMoveToken, otherToken, selectedMoveToken } from "domain-model";
import Square from "../components/Square";
import GameOver from "../components/GameOver";
import { useSocket } from "../context/SocketProvider";
import { getPlayer } from "../utils/playerOps";
import { updateSession } from "../store/sessionReducer";
import './GameBoard.css';

const GameBoardRedo: FC<{session: SessionFull, playerId: string}> = ({ session, playerId }) => {
  const { emit, on } = useSocket();
  const { board, currentPlayer } = session;
  const [ moveToken, setMoveToken ] = useState<MoveToken>(noMoveToken);
  const isSpectator = session.spectators.includes(playerId);
  const ourTurn = currentPlayer.id === playerId;
  const dispatch = useDispatch();

  useEffect(() => {
    on('session-update', (session: SessionFull) => {
      setMoveToken(noMoveToken);
      dispatch(updateSession(session));
    });

    on('game-over', (session: SessionFull) => {
      setMoveToken(noMoveToken);
      dispatch(updateSession(session));
    });
  }, []);

  const handleMoveOpponent = (space: Space, loc: TokenLocation) => {
    switch(moveToken.kind) {
      case 'noMoveToken':
        if (space.token === otherToken(currentPlayer.token)) {
          setMoveToken(selectedMoveToken(loc));
        }
        return;
      case 'selected-move-token':
        if (!isAdjacent(loc, moveToken.from) && space.token !== Token.Empty) {
          // the condition above might not be correct
          setMoveToken(noMoveToken);
        } else {
          emit('move-opponent', {
            roomId: session.roomId,
            move: movedTokenTo(moveToken, loc)
          })
        }
        return;
      case 'moved-token': return; // already moved, can't do anything
    }
  }

  const handlePlace = (loc: TokenLocation, space: Space) => {
    if (space.token !== Token.Empty) return; // can't place on non-empty space
    setMoveToken(noMoveToken);
    emit('place-token', {
      roomId: session.roomId,
      space: loc
    })
  }

  const handleClick = (player: Player, space: Space, loc: TokenLocation) => {
    if (space.token === otherToken(player.token) || (moveToken.kind === 'selected-move-token' && space.token === Token.Empty)) {
      console.log('moving opponent!')
      handleMoveOpponent(space, loc);
    } else {
      console.log('placing a token!')
      handlePlace(loc, space);
    }
  }

  const handleReset = () => {
    emit('reset-game', session.roomId);
  }

  const isPlayer1 = session.player1.id === playerId;
  const player = getPlayer(session, playerId);
  const turnMessage = ourTurn
    ? "your turn"
    : "their turn";
  const identifier = isSpectator ? 'a spectator' : player?.token + ' player';

  return (
    <div className="game-board">
      <div>
        You are participating as {identifier}
      </div>
      {board.map((row, i) => (
        <div key={i} className="board-row">
          {row.map((space, j) => (
            <Square
              key={space.id}
              value={space}
              onClick={() => player && handleClick(player, space, {row: i, col: j})}
              disabled={!ourTurn || session.winners.length > 0}
              isSelected={moveToken.kind === 'selected-move-token' && moveToken.from.row === i && moveToken.from.col === j}
            />
          ))}
        </div>
      ))}
      {session.winners.length > 0 || isSpectator ? '' : turnMessage}
      <GameOver winners={session.winners} onReset={isPlayer1 ? handleReset : undefined} />
    </div>
  );
}

export default GameBoardRedo;
