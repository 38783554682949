import { FC } from "react";
import "./CopyButton.css";

const CopyButton: FC<{ text: string }> = ({ text }) => {
  const onClick = () => {
    navigator.clipboard.writeText(text)
  }

  return (
    <span onClick={onClick} className="copy-container">
      <div className="copy-background" />
      <div className="copy-foreground" />
    </span>
  )
}

export default CopyButton;
