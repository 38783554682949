import React from "react"
import "./Welcome.css";
import { useNavigate } from "react-router-dom";

const Welcome: React.FC = () => {
  const navigate = useNavigate();

  return (
    <div className="welcome-container">
      <button className="welcome-button" onClick={() => navigate('/create')}>Start Game</button>
      <p className="welcome-message">
        ZYZIGY is based on FlexIQ's Orbito game. This webapp is only intended to play online with friends.
        To play offline, please consider purchasing their boardgame at <a href="https://flexiqgames.com/en/product/orbito/">their website</a>.
      </p>
    </div>
  )
}

export default Welcome;
