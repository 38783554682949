import React, { useEffect }  from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLoaderData, useParams, useRouteLoaderData } from 'react-router-dom';
import { AppState } from '../store';
import { useSocket } from '../context/SocketProvider';
import { Session, SessionFull, SessionWaiting } from 'domain-model';
import { SessionUninitialized, updateSession } from '../store/sessionReducer';
import GameBoardRedo from './GameBoard';

const Game: React.FC = () => {
  const { emit, once, socket } = useSocket();
  const playerId = socket?.id!; // we must have a socket id to render this component
  const gameId = useParams().gameId!; // we must have a gemeId to render this component
  const session = useSelector((state: AppState) => state.session);
  const sessionKind = session.kind;
  const dispatch = useDispatch();

  useEffect(() => {
    if (sessionKind === 'session-uninitialized') {
      emit('join-game', gameId);
    }

    once('joined-game', (session: SessionFull) => {
      dispatch(updateSession(session));
    });
  }, []);

  const waiting = (session: SessionWaiting) => (
    <>
      <h1>Waiting for another player to join...</h1>
      <p>Game ID: {session.roomId}</p>
      <p>Game Creator: {session.player1.id} as {session.player1.token}</p>
    </>
  );

  const uninitialized = (session: SessionUninitialized) =>  (
    <>
      <div>Session was not initialized. Join a valid session, or create a new game</div>
      <Link to="/">Start over</Link>
    </>
  )

  const gameBoard = (session: SessionFull) => (
    <GameBoardRedo session={session} playerId={playerId} />
   );

  const render = (() => {
    switch(sessionKind) {
      case 'session-full':
        return gameBoard(session);
      case 'session-waiting':
        return waiting(session);
      case 'session-uninitialized':
        return uninitialized(session);
    }
  })();

  return (
    <div>
      {render}
    </div>
  );
}

export default Game;
