import { createSlice } from '@reduxjs/toolkit';
import { PayloadAction } from '@reduxjs/toolkit';
import { Session } from 'domain-model';

export type SessionUninitialized = {
  kind: 'session-uninitialized';
}

export type SessionState = Session | SessionUninitialized;

const initialState = { kind: 'session-uninitialized' } as SessionState;

export enum SessionActions {
  Update = 'session/update',
}

const sessionSlice = createSlice({
  name: 'session',
  initialState,
  reducers: {
    updateSession: (_, action: PayloadAction<Session>) => action.payload,
  }
});

export const { updateSession } = sessionSlice.actions;

export default sessionSlice.reducer;
