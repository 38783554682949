import React from 'react';
import './Square.css';
import { Space } from 'domain-model';

interface SquareProps {
  value: Space;
  onClick: () => void;
  disabled: boolean;
  isSelected: boolean;
}

const Square: React.FC<SquareProps> = ({ disabled, value, onClick, isSelected }) => {

  return (
    <button disabled={disabled} className="square" onClick={onClick}>
      <div className={`circle ${value.token.toLowerCase()} ${isSelected ? 'selected' : ''}`} />
    </button>
  );
};

export default Square;
