import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import Welcome from './pages/Welcome';
import CreateGame from './pages/CreateGame';
import Game from './pages/Game';

const App: React.FC = () => {
  return (
    <div className="App">
      <h1>ZYZIGY</h1>
      <Router>
        <Routes>
          <Route path="/" element={<Welcome/>} />
          <Route path="/create" element={<CreateGame/>} />
          <Route path="/game/:gameId" element={<Game/>} />
          <Route path="*" element={<div>Not Found</div>} />
        </Routes>
      </Router>
    </div>
  );
};

export default App;
