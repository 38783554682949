"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.movedTokenTo = exports.selectedMoveToken = exports.noMoveToken = exports.isAdjacent = exports.otherToken = exports.Token = void 0;
var Token;
(function (Token) {
    Token["Blue"] = "blue";
    Token["Red"] = "red";
    Token["Empty"] = "empty";
})(Token || (exports.Token = Token = {}));
;
var otherToken = function (token) {
    switch (token) {
        case Token.Blue: return Token.Red;
        case Token.Red: return Token.Blue;
    }
};
exports.otherToken = otherToken;
;
var isAdjacent = function (a, b) {
    return Math.abs(a.col - b.col) + Math.abs(a.row - b.row) === 1;
};
exports.isAdjacent = isAdjacent;
exports.noMoveToken = { kind: 'noMoveToken', };
var selectedMoveToken = function (from) { return ({ kind: 'selected-move-token', from: from }); };
exports.selectedMoveToken = selectedMoveToken;
var movedTokenTo = function (selected, to) { return (__assign(__assign({}, selected), { kind: 'moved-token', to: to })); };
exports.movedTokenTo = movedTokenTo;
