import { configureStore } from "@reduxjs/toolkit";
import sessionReducer from "./sessionReducer";
import { createLogger } from "redux-logger";

const middlewares = process.env.NODE_ENV === 'development'
  ? [createLogger({collapsed: true})]
  : [];

export const store = configureStore({
  reducer: {
    session: sessionReducer
  },
  middleware: getDefaultMiddleware => getDefaultMiddleware().concat(middlewares),
});

export type AppState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;
