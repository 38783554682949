import { SessionFull, Player } from "domain-model";

// export const shiftBoard = (board: Board): Board => {
//   const newBoard = board.map(row => [...row]);
//   const outerRing: Player[] = [
//     ...newBoard[0],
//     newBoard[1][3],
//     newBoard[2][3],
//     ...newBoard[3].reverse(),
//     newBoard[2][0],
//     newBoard[1][0],
//   ];
//   const innerRing: Player[] = [
//     ...newBoard[1].slice(1, 3),
//     ...newBoard[2].slice(1, 3).reverse()
//   ];

//   const shiftedOutterRing = [outerRing.pop(), ...outerRing] as Player[];
//   const shiftedInnerRing = [innerRing.pop(), ...innerRing] as Player[];

//   // outter ring
//   newBoard[0] = shiftedOutterRing.slice(0, 4);
//   newBoard[1][3] = shiftedOutterRing[4];
//   newBoard[2][3] = shiftedOutterRing[5];
//   newBoard[3] = shiftedOutterRing.slice(6, 10).reverse();
//   newBoard[2][0] = shiftedOutterRing[10];
//   newBoard[1][0] = shiftedOutterRing[11];

//   // inner ring
//   newBoard[1][1] = shiftedInnerRing[0];
//   newBoard[1][2] = shiftedInnerRing[1];
//   newBoard[2][2] = shiftedInnerRing[2];
//   newBoard[2][1] = shiftedInnerRing[3];

//   return newBoard;
// };

// export const getWinner = (board: Board): Player => {
//   const size = board.length;
//   // Check rows
//   for (let i = 0; i < size; i++) {
//     const maybeRowWinner = board[i][0];
//     if (maybeRowWinner !== Player.Empty && board[i].every(cell => cell === maybeRowWinner)) {
//       return maybeRowWinner
//     }
//   }

//   // Check columns
//   for (let i = 0; i < 4; i++) {
//     const maybeColWinner = board[0][i];
//     if (maybeColWinner !== Player.Empty && board.every(row => row[i] === maybeColWinner)) {
//       return maybeColWinner;
//     }
//   }

//   // Check diagonals
//   const maybeDiagonalWinner = board[0][0];
//   if (maybeDiagonalWinner !== Player.Empty && board.every((row, i) => row[i] === maybeDiagonalWinner)) {
//     return maybeDiagonalWinner;
//   }

//   const maybeOtherDiagonalWinner = board[0][3];
//   if (maybeOtherDiagonalWinner !== Player.Empty && board.every((row, i) => row[3 - i] === maybeOtherDiagonalWinner)) {
//     return maybeOtherDiagonalWinner;
//   }

//   return Player.Empty;
// }

export const getPlayer = (session: SessionFull, playerId: string): Player | null => {
  if (session.player1.id === playerId) return session.player1
  if (session.player2.id === playerId) return session.player2;
  return null
}

