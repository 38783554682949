import { ClientToServerEvents, OrbitsClientSocket, ServerToClientEvents } from "domain-model";
import React, { ReactNode, createContext, useContext, useEffect, useRef, useState } from "react";
import { io } from "socket.io-client";

interface SocketContext {
  socket?: OrbitsClientSocket;
  removeListener: (event: keyof ServerToClientEvents, callback: ServerToClientEvents[typeof event]) => void;
  on: (event: keyof ServerToClientEvents, callback: ServerToClientEvents[typeof event]) => void;
  once: (event: keyof ServerToClientEvents, callback: ServerToClientEvents[typeof event]) => void;
  emit: (event: keyof ClientToServerEvents, ...message: Parameters<ClientToServerEvents[typeof event]>) => void;
}

const SocketContext = createContext<SocketContext>({} as SocketContext);

export const SocketProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const socketRef = useRef<OrbitsClientSocket | undefined>(undefined);
  const [ connected, setConnected ] = useState(false);

  useEffect(() => {
    socketRef.current = io(process.env.REACT_APP_WS_URL, {
      path: "/ws",
    })

    socketRef.current?.once('connect', () => setConnected(true));

    return () => {
      socketRef.current?.disconnect();
    }
  }, []);

  function on(event: keyof ServerToClientEvents, callback: ServerToClientEvents[typeof event]) {
    socketRef.current?.on(event, callback);
  }

  function once(event: keyof ServerToClientEvents, callback: ServerToClientEvents[typeof event]) {
    socketRef.current?.once(event, callback);
  }

  function emit(event: keyof ClientToServerEvents, ...message: Parameters<ClientToServerEvents[typeof event]>) {
    socketRef.current?.emit(event, ...message);
  }

  function removeListener(event: keyof ServerToClientEvents, callback: ServerToClientEvents[typeof event]) {
    socketRef.current?.off(event, callback);
  }

  return (
    <SocketContext.Provider value={{ socket: socketRef.current, on, once, emit, removeListener }}>
      {connected ?
        children :
        <div>Connecting...</div> }
    </SocketContext.Provider>
  )
}

export const useSocket = () => {
  const context = useContext(SocketContext);
  if (context === undefined) {
    throw new Error('Could not generate a websocket context');
  }
  return context;
}
