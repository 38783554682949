import { Player } from 'domain-model';
import React from 'react';

interface GameOverProps {
  winners: Player[];
  onReset?: () => void;
}

const GameOver: React.FC<GameOverProps> = ({ winners, onReset }) => {
  const winMessage = winners.length === 1
    ? `Winner: ${winners[0].token}`
    : winners.length === 2 ? 'Tie game!' : '';
  return winners.length > 0 ? (
    <div className="game-over">
      <div>{winMessage}</div>
      {onReset
        ? <button onClick={onReset}>Play Again</button>
        : null}
    </div>
  ) : null;
}

export default GameOver;
