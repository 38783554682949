import { Session } from "domain-model";
import { useSocket } from "../context/SocketProvider";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { updateSession } from "../store/sessionReducer";
import { AppState } from "../store";
import CopyButton from "../components/CopyButton";

const CreateGame: React.FC = () => {
  const { on, emit, removeListener } = useSocket();
  const dispatch = useDispatch();
  const session = useSelector((state: AppState) => state.session);

  const getGameId = (() => {
    switch(session.kind) {
      case 'session-uninitialized': return null;
      default: return session.roomId
    }
  })()

  useEffect(() => {
    const onGameCreated = (data: Session) => dispatch(updateSession(data));
    on('game-created', onGameCreated);
    emit('create-game');

    return () => removeListener('game-created', onGameCreated);
  }, []);

  const gameUrl = window.location.host + `/game/${getGameId}`;

  return (
    <div>
      <h2>Create Game</h2>
      {
        getGameId ?
          <p>Game ID: <Link to={`/game/${getGameId}`} >{getGameId}</Link> <CopyButton text={gameUrl} /></p> :
          <p>Creating game...</p>
      }
    </div>
  );
}

export default CreateGame;
